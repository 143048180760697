import { template as template_df2b6936005e4579b66280e4bf219a51 } from "@ember/template-compiler";
const FKControlMenuContainer = template_df2b6936005e4579b66280e4bf219a51(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
